<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="event">
      <v-alert type="warning" v-if="event.notPublished">{{$t('eventmanager.not-published-msg')}}</v-alert>
      <EventHeader :event="event"/>

      <v-card-title class="title">{{$t('events.welcome.getting-started')}}</v-card-title>
      <v-card-text>Please follow these instructions to get started and connect with {{event.name}}.</v-card-text>

      

      <v-alert v-if="isConnected" tile prominent type="success">
        <p>{{$t('events.welcome.joined-message', {event: event.name})}}</p>
        <p>
          <span v-if="status.verification_code">{{$t('events.welcome.joined-code', {code: status.verification_code})}}</span>
          <span v-if="status.races">{{$t('events.welcome.joined-races', {races: status.races.join(', ') })}}</span>
        </p>
        <p v-if="status.reg_name">
          <i18n path="events.welcome.reg-name">
            <strong >{{status.reg_name}}</strong>
          </i18n>
        </p>
        <p>
          <v-btn x-large :to="{name: 'eventWelcome', params: {id: this.event.id}}" :exact="true">
            <v-icon class="mr-2">fa-info-circle</v-icon>
            {{ $t('events.welcome.manage-connection') }}
          </v-btn>
        </p>
      </v-alert>

      <v-card-title class="subheader">Step 1: <strong>Download the app</strong></v-card-title>
      <v-card-text>
        <p>{{$t('events.download-app-msg')}}</p>
        <p>
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
          <a href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </p>
        <h4>Huawei</h4>
        <p>
          Want to install on a Huawei phone? Please reach out to us for a download link.
        </p>
        <p class="text-muted">{{$t('events.download-app-disclaimer')}}</p>
      </v-card-text>


      <v-card-title class="subheader">Step 2: <strong>Scan the QR code from within the app</strong></v-card-title>
      <v-card-text>
        <p>Open our app and click the Scan QR option to scan the QR code below.</p>
        <p>
          <script type="application/javascript" src="/js/qrcodegen-v1.8.0-es6.js"></script>
          <canvas ref="canvas" ></canvas>
        </p>

        <p>
          Url: <code>{{ directJoinLink }}</code>
        </p>
      </v-card-text>

      <v-card-title class="subheader">Step 3: <strong>Login or Register</strong></v-card-title>
      <v-card-text>
        <p>Connect using your preferred method, or continue without an account. Your challenge and code are preselected so you don't need to enter those again.</p>

        <v-expansion-panels tile class="mt-4">
          <v-expansion-panel>
            <v-expansion-panel-header>Using both app and website?</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>When you use both the app and website, please make sure to log in with the same account on both devices. E.g. when you connect with Garmin, please make sure to do so on both devices.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-title class="subheader">Step 4: <strong>Connect your favorite fitness tracker (optional)</strong></v-card-title>
      <v-card-text>
        <p>One you're part of the challenge you can connect your favorite fitness tracker to automatically track your steps and activities. Alternatively, you can use the integrated activity or step tracker from our app or use manual entry.</p>
      </v-card-text>



      <v-card-title class="subheader">Tutorials</v-card-title>
      <v-card-text>These how-to guides might be helpful to get you started in no time!</v-card-text>
      <v-row class="mx-3"> 
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Join%20challenge%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Join a Challenge</h3>
            <p>How to join a (step) challenge using our app.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Garmin%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Garmin</h3>
            <p>How to connect with Garmin.</p>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Strava%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Strava</h3>
            <p>How to connect with Strava.</p>
          </v-card>
        </v-col> -->
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Apple%20Watch%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Apple Watch</h3>
            <p>How to connect your Apple Watch.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Fitbit%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Fitbit</h3>
            <p>How to connect your Fitbit.</p>
          </v-card>
        </v-col>
        <!-- hide voor non-step events? -->
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Enable%20Step%20Tracking%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Enable Step Tracking</h3>
            <p>How to enable step tracking in the app.</p>
          </v-card>
        </v-col>
        <!-- hide voor non-manual entries? -->
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20QuickEntry%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Add a Manual Entry</h3>
            <p>How to add a manual entry.</p>
          </v-card>
        </v-col>
      </v-row>      
      <br/>
    </v-card>


  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import { getAuth } from "firebase/auth";

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    EventHeader,
    EventJoinCard,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      status: null,
      joinInfo: null,
      code: null,
      email: null,
      showRecoveryDialog: false,
      showQr: false,
      recoveryType: null,
    };
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);

    EventBus.$on('login-state-change', async user => {
      await this.getEventStatus();
    });

    const QRC = qrcodegen.QrCode;
    const qr = QRC.encodeText(this.directJoinLink, QRC.Ecc.MEDIUM);
    this.drawCanvas(qr, 8, 2, '#fff', '#000', this.$refs.canvas);
  },

  methods: {

    
	// Draws the given QR Code, with the given module scale and border modules, onto the given HTML
	// canvas element. The canvas's width and height is resized to (qr.size + border * 2) * scale.
	// The drawn image is purely dark and light, and fully opaque.
	// The scale must be a positive integer and the border must be a non-negative integer.
	drawCanvas(qr, scale, border, lightColor, darkColor, canvas) {
		if (scale <= 0 || border < 0)
			throw new RangeError("Value out of range");
		const width = (qr.size + border * 2) * scale;
		canvas.width = width;
		canvas.height = width;
		let ctx = canvas.getContext("2d");
		for (let y = -border; y < qr.size + border; y++) {
			for (let x = -border; x < qr.size + border; x++) {
				ctx.fillStyle = qr.getModule(x, y) ? darkColor : lightColor;
				ctx.fillRect((x + border) * scale, (y + border) * scale, scale, scale);
			}
		}
	},
      
    async getEventStatus() {
      if (this.user) {
        var response = await profileService.getEventStatus(this.event.id);
        this.status = response.data;
      }
    },

    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      await this.getEventStatus();
      if (this.event.verification_method === 'ALLOWLIST') {
        var response = await eventService.getEventJoinInfo(this.event.id);
        this.joinInfo = response.data;
        console.log('this.joinInfo', this.joinInfo);
      }
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    
    async recoverInviteLink() {
      this.email = this.code = this.showQr = this.recoveryType = null;
      if (this.event.verification_method === 'REG_DATA') {
        this.joinInfo = null;
      }
      this.showRecoveryDialog = true;
    },
    async loadJoinStatusByCode() {
      var response = await eventService.getJoinInfo(this.event.id, this.code);
      this.joinInfo = response.data;
    },
    async loadJoinStatusByEmail() {
      var response = await eventService.getJoinInfoByEmail(this.event.id, this.email); 
      this.joinInfo = response.data;
    },

  },
  computed: {
    directJoinLink() {
      https://retos.asdeporte.com/r/a/com.asdeporte.kardias2022/test-carrera-kardias-2022/join?code=h0wy-jja81
      return `${tenant.homeUrl}r/a/app.corporatefitness/${this.$route.params.id}/join?code=${this.$route.query.code}`;
      //return `${tenant.homeUrl}register?event=${this.$route.params.id}&verificationCode=${this.$route.query.code}`;
    },
    isConnected() {
      return this.status && this.status.connected;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.welcome.getting-started'), disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

